<template>
  <div>
   <UnitIcon :key="constants.GROUP_ICONS.UNITS" v-if="entityType == constants.GROUP_ICONS.UNITS"/>
   <DeviceIcon :key="constants.GROUP_ICONS.DEVICES"  v-if="entityType == constants.GROUP_ICONS.DEVICES"/>
   <AlertIcon :key="constants.GROUP_ICONS.ALERTS"  v-if="entityType == constants.GROUP_ICONS.ALERTS"/>
   <NotificationIcon :key="constants.GROUP_ICONS.NOTIFICATIONS"  v-if="entityType == constants.GROUP_ICONS.NOTIFICATIONS"/>
   <ScheduleIcon :key="constants.GROUP_ICONS.SCHEDULES"  v-if="entityType == constants.GROUP_ICONS.SCHEDULES"/>
   <WebhookIcon :key="constants.GROUP_ICONS.WEBHOOKS"  v-if="entityType == constants.GROUP_ICONS.WEBHOOKS"/>
   <RepeaterIcon :key="constants.GROUP_ICONS.REPEATERS"  v-if="entityType == constants.GROUP_ICONS.REPEATERS"/>
   <OperatorIcon :key="constants.GROUP_ICONS.OPERATORS"  v-if="entityType == constants.GROUP_ICONS.OPERATORS"/>
   <ReportIcon :key="constants.GROUP_ICONS.REPORTS"  v-if="entityType == constants.GROUP_ICONS.REPORTS"/>
   <Maintenance :key="constants.GROUP_ICONS.MAINTENANCE"  v-if="entityType == constants.GROUP_ICONS.MAINTENANCE"/>
   <Geofennces :key="constants.GROUP_ICONS.GEOFENCES"  class="geo-fn" v-if="entityType == constants.GROUP_ICONS.GEOFENCES"/>
   <Routes :key="constants.GROUP_ICONS.ROUTES"  class="geo-route-fn" v-if="entityType == constants.GROUP_ICONS.ROUTES"/>
  <SimIcon :key="constants.GROUP_ICONS.SIM"  class="geo-route-fn" v-if="entityType == constants.GROUP_ICONS.SIM"/>
 </div>
</template>
    <script>
      import UnitIcon from "@/assets/images/icons/group-icons/Unit.svg";
      import DeviceIcon from "@/assets/images/icons/group-icons/Device.svg";
      import AlertIcon from "@/assets/images/icons/group-icons/Alerts.svg";
      import NotificationIcon from "@/assets/images/icons/group-icons/Notifications.svg";
      import ScheduleIcon from "@/assets/images/icons/group-icons/Schedules.svg";
      import WebhookIcon from "@/assets/images/icons/group-icons/Webhooks.svg";
      import RepeaterIcon from "@/assets/images/icons/group-icons/Repeaters.svg";
      import OperatorIcon from "@/assets/images/icons/group-icons/Operators.svg";
      import ReportIcon from "@/assets/images/icons/group-icons/Reports.svg";
      import Maintenance from "@/assets/images/icons/group-icons/maintenance.svg";
      import Geofennces from "@/assets/images/icons/group-icons/geo.svg"; 
      import Routes from "@/assets/images/icons/group-icons/Route.svg";
      import SimIcon from "@/assets/images/icons/group-icons/SimIcon.svg";
      import constants from "@/utils/constants";

   
      export default {
        components: {
          UnitIcon,
          AlertIcon,
          DeviceIcon,
          NotificationIcon,
          ScheduleIcon,
          WebhookIcon,
          RepeaterIcon,
          OperatorIcon,
          ReportIcon,
          Maintenance,
          Geofennces,
          Routes,
          SimIcon
        },
        props: ["entityType"]
      };
    </script>

    <style lang="scss">
      @import "@core/scss/vue/libs/vue-select.scss";
      .group-svg{
        clear: both;
      }
.normal-alerts{    
  .group-svg {
    path{
      fill: var(--secondary);
    }
    .red-svg {
        fill: var(--danger);
    }
    .device-svg{
      stroke: var(--secondary);
      path{
        fill: none;
        stroke: var(--secondary);
      } 
    }
  }
}
.active-alerts {
  .group-svg {
    path{
      fill: var(--primary);
    }
    .red-svg {
        fill: var(--danger);
    }
    .device-svg{
      stroke: var(--primary);
      path{
        fill: none;
        stroke: var(--primary);
      } 
    }
    .geo-fn path{
      stroke: var(--primary);
      path{
        fill: none;
        stroke: var(--primary);
      } 
    }
    .geo-route-fn path{
      path{
        fill: none;
        stroke: var(--primary);
      } 
    }
  }
}
.dark-layout {
.normal-alerts{    
  .group-svg {
    path{
      fill: var(--light);
    }
    .red-svg {
        fill: var(--danger);
    }
    .device-svg{
      stroke: var(--light);
      path{
        fill: none;
        stroke: var(--light);
      } 
    }
  }
}
.active-alerts {
  .group-svg {
    path{
      fill: var(--primary);
    }
    .red-svg {
        fill: var(--danger);
    }
    .device-svg{
      stroke: var(--primary);
      path{
        fill: none;
        stroke: var(--primary);
      } 
    }
  }
}}
    </style>
  </template>
</template>
